import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ACCESS,
  URL,
  REFRESH,
  BEARER,
  setTokenAndUsername,
} from "./../services/authProvider";
const createOnChange = (ref) => (event) => {
  ref.current = event.target.value;
};
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  heading: {
    textAlign: "center",
    paddingBottom: "20px",
  },
}));

const Injury = () => {
  const des = useRef("");
  const weeks = useRef("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onSubmit = (event) => {
    event.preventDefault();
    let subcat = selectedCounty === "Mental Health Issue" ? "NA" : selectedCity;
    let payload = {
      injurySubCat: subcat,
      injuryCat: selectedCounty,
      description: des.current,
      weeks: weeks.current,
    };
    fetch(`${URL}injury/`, {
      method: "POST",
      headers: {
        Authorization: BEARER,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Request failed.");
      })
      .then((data) => {
        history.push("/main");
      });
  };

  const [cities, setCities] = useState([]);
  const [selectedCounty, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const injuryOptions = {
    "Mental Health Issue": [],
    "Musculoskeletal Issue": [
      "Back Injury",
      "Arm Injury",
      "Leg Injury",
      "Other/Multiple areas",
    ],
    "Medical issue": [
      "Covid19 Related",
      "Neurological",
      "Cardiovascular",
      "Respiratory",
      "Rheumatological",
      "Cancer",
      "Pregnancy Related",
      " Other Medical Issue",
    ],
  };

  const countryList = Object.keys(injuryOptions).map((key) => ({
    name: key,
  }));
  function handleCountrySelect(e) {
    const countrySel = e.target.value;
    const citiesSel = countrySel !== "" ? injuryOptions[countrySel] : "";
    setSelectedCountry(countrySel);
    setCities(citiesSel);
    setSelectedCity("");
  }
  function handleCitySelect(e) {
    const citiesSel = e.target.value;
    setSelectedCity(citiesSel);
    console.log();
  }
  const classes = useStyles();

  return (
    <>
      <Typography component="h1" variant="h5" className={classes.heading}>
        Medfit Occupational Injury & Illness Registration Form
      </Typography>
      <Typography component="body1" variant="body1">
        The following questions relate to your own perception of your
        injury/illness, health, and prospects for recovery. Some of the
        questions ask you to rate your answers on a scale, so please select the
        answer which most closely relates to you. A member of our team will be
        in contact by phone to discuss your answers, as we know questionnaires
        never fully capture the full meaning of an injury or illness. This
        initial form is only a guide to get the assessment process started.
      </Typography>
      <form  className={classes.form} Validate onSubmit={onSubmit}>
        <TextField
          id="description"
          label="Can you briefly describe your injury/illness?"
          required
          variant="outlined"
          margin="normal"
          multiline
          rows="3"
          fullWidth
          maxLength="500"
          onChange={createOnChange(des)}
        />
        <TextField
          id="PRIMARY-injury"
          select
          label="How would you broadly categorise your injury / illness?"
          required
          fullWidth
          variant="outlined"
          margin="normal"
          value={selectedCounty}
          onChange={(e) => handleCountrySelect(e)}
        >
          {countryList.map((country, key) => (
            <MenuItem key={key} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </TextField>
        {selectedCounty !== "Mental Health Issue" && (
          <TextField
            id="pain-area"
            select
            label="If you have a Medical or Musculoskeletal issue, can you clarify in more detail from the following list?"
            required
            fullWidth
            variant="outlined"
            margin="normal"
            value={selectedCity}
            onChange={(e) => handleCitySelect(e)}
          >
            {cities.map((city, key) => (
              <MenuItem key={key} value={city}>
                {city}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          id="date"
          label="What was your last day at work?"
          type="date"
          color="primary"
          margin="normal"
          required
          fullWidth
          helperText="If you are not sure of the exact date an approximate will be suffice"
          onChange={createOnChange(weeks)}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div>
          <Button
            disabled={loading}
            color="primary"
            type="submit"
            className={classes.submit}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default Injury;
