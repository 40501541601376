import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { TOKEN } from './../services/authProvider';

export default function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (TOKEN ? <Redirect to="/feel" /> : <Component {...props} />)}
    />
  );
}
