import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "./../header/Header";
import Personal from "./../personaldetails/Personal";
import Injury from './../injury/Injury';
import Score from "./../score/Score";
import Complete from "./../complete/Complete";
import { LoadingDots } from "@workday/canvas-kit-react";
import { makeStyles } from '@material-ui/core/styles';
import Copyright from "../Copyright";
import {
  USERNAME,
  TOKEN,
  ACCESS,
  URL,
  REFRESH,
  setTokenAndUsername,
} from "./../services/authProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1),
    alignItems: "center",
  },
  header: {
    textAlign: "center",
    margin: theme.spacing(3, 0, 2),
  },
}));


const MainPage = () => {
  const [loading, setLoading] = useState(true);
  const [ctx, setCtx] = useState("false");

  useEffect(() => {
    var bearer = "Bearer " + ACCESS;
    fetch(`${URL}usercontext/`, {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Request failed.");
      })
      .then((data) => {
        setCtx(data.context);
        /*if (data.context === 'personal'){
  setCtx(true)
}else if (data.context === 'injury'){
  setInj(true);
}*/

        setLoading(false);
        if (data.context === "invalid user type") {
          setLoading(true);
          window.location.reload();
        }
      });
  });
  const classes = useStyles();

  if (loading)
    return (
      <div>
        {" "}
        <LoadingDots style={{ width: "100%", textAlign: "center" }} />{" "}
      </div>
    );

  return (
    <>
      <Header />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
      {ctx === "personal" && <Personal />}
      {ctx === "injury" && <Injury />}
      {(ctx === 'answers') && <Score/>}
      {(ctx === 'complete') && <Complete/>}
      </div>
      <Copyright />
    </Container>
    </>
  );
};

export default MainPage;
