import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Copyright from "../Copyright";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  main: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    display: "block", // Fix IE 11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  heading: {
    textAlign: "center",
    paddingBottom: "20px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px ${
      theme.spacing.unit * 4
    }px`,
  },
}));

const Complete = () => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.heading}>
          Thank for submitting your registration form!
        </Typography>
        <Typography component="body1" variant="body1">
          A member of Medfit’s clinical team will contact you within 2 working
          days to discuss your registration details. You will be phoned from the
          following number 01-2784423. In the meantime, if you have any
          questions, please email{" "}
          <Link href="mailto:info@medfit.ie">info@medfit.ie</Link> and we will
          respond to your query as soon as possible.
        </Typography>
        <Copyright />
      </Paper>
    </main>
  );
};

export default Complete;
