import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Terms from "./Terms";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import counties from "./counties";
import {
  ACCESS,
  URL,
  REFRESH,
  BEARER,
  isExperied,
  setTokenAndUsername,
  resetToken,
} from "./../services/authProvider";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1),
    alignItems: "center",
  },
  header: {
    textAlign: "center",
    margin: theme.spacing(3, 0, 2),
  },
}));

const createOnChange = (ref) => (event) => {
  ref.current = event.target.value;
};
const Personal = () => {
  const [value, setValue] = React.useState("female");
  const [county, setCounty] = React.useState("");
  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };
  const handleCountyChange = (event) => {
    setCounty(event.target.value);
  };
  const [loading, setLoading] = useState(false);
  const firstname = useRef("");
  const lastname = useRef("");
  const address1 = useRef("");
  const address2 = useRef("");
  const eircode = useRef("");
  const [phone, setPhone] = React.useState("+353");
  const dob = useRef("1990-05-24");

  const history = useHistory();
  const onClick = ({ event }) => {
    event.preventDefault();
  };
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = () => {
    setScroll("paper");
    setOpen(true);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    //setLoading(true);
  };
  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  const sendPersonalDeatils = async (event) => {
    setOpen(false);
    setLoading(true);
    let payload = {
      fname: firstname.current,
      lname: lastname.current,
      gender: value,
      phone: phone,
      dob: dob.current,
      address1: address1.current,
      address2: address2.current,
      county: county,
      eircode: eircode.current,
    };
    if (isExperied()) {
      let bod = {
        refresh: REFRESH,
      };
      const x = await fetch(`${URL}tokenrefresh/`, {
        method: "POST",
        headers: {
          Authorization: BEARER,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bod),
      });
      const json = await x.json();
      resetToken(json.access);
      if (json.access === ACCESS) {
        const x = await fetch(`${URL}personal/`, {
          method: "POST",
          headers: {
            Authorization: BEARER,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        const json = await x.json();
        history.push("/main");
      }
    } else {
      const x = await fetch(`${URL}personal/`, {
        method: "POST",
        headers: {
          Authorization: BEARER,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const json = await x.json();
      history.push("/main");
    }
  };

  const classes = useStyles();

  return (
    <>
      <Typography component="h1" variant="h5" className={classes.header}>
        Personal Details
      </Typography>
      <form className={classes.form} Validate onSubmit={onSubmit}>
        <TextField
          id="firstname"
          label="First Name"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          onChange={createOnChange(firstname)}
        />
        <TextField
          id="lastname"
          label="Last Name"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          onChange={createOnChange(lastname)}
        />
        <TextField
          id="Address1"
          label="Address Line 1"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          onChange={createOnChange(address1)}
        />
        <TextField
          id="Address2"
          label="Address Line 2"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={createOnChange(address2)}
        />

        <FormControl fullWidth required className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">County</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            controlled
            onChange={handleCountyChange}
          >
            {counties.map((count) => (
              <MenuItem value={count}>{count}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="eircode"
          label="Eircode"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={createOnChange(eircode)}
        />
        <MuiPhoneNumber
          label="Phone"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          defaultCountry="ie"
          regions={["european-union"]}
          onChange={(phone) => setPhone(phone)}
        />

        <TextField
          id="date"
          label="Date Of Birth"
          type="date"
          defaultValue="1990-05-24"
          margin="normal"
          required
          fullWidth
          onChange={createOnChange(dob)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth component="fieldset">
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup
            row
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              color="primary"
              value="female"
              control={<Radio color="primary" />}
              label="Female"
            />
            <FormControlLabel
              color="primary"
              value="male"
              control={<Radio color="primary" />}
              label="Male"
            />
            <FormControlLabel
              color="primary"
              value="other"
              control={<Radio color="primary" />}
              label="Other"
            />
          </RadioGroup>
        </FormControl>

        <Button
          disabled={loading}
          type="submit"
          color="primary"
          className={classes.submit}
        >
          Submit
        </Button>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          Patient Consent to Store and Share Information
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText>
            <Terms />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={sendPersonalDeatils}>
            I agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Personal;
