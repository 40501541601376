import React from 'react';
const Terms = () => {

  return (
  <>
  Our Notice of Privacy provides information about how we may use and disclose protected health information about you. The Notice contains a Patient Rights section describing your rights under the law. You have the right to review our notice before signing this consent and prior to any service being provided to you by the Clinic. The Clinic reserves the right to change the Notice of Privacy Policies. If we change our notice, you may obtain a revised copy by sending a letter to the Clinic’s Data Protection Officer or by asking at reception.

<div>By signing this form, you acknowledge that you have been given the opportunity to read the Clinic’s Notice of Privacy Practices prior to any service being provided to you by this Clinic, and you consent to the store, use and disclosure of your medical information to other healthcare providers involved in your care and for treatment, payment and healthcare operations. You have the right to revoke this consent in writing, signed by you. However, such a revocation shall not affect any disclosures we have already made in reliance on your prior consent. The Practice provides this form to comply with the General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679) </div>

<span><u>By signing this form, I understand that:</u>
<ul>
<li> Protected health information may be disclosed or used for treatment, payment, or healthcare operations. </li>

<li>  The Clinic reserves the right to change the privacy policy as allowed by law. </li>

<li> The Clinic has the right to restrict the use of the information. </li>

<li> The patient has the right to revoke this consent in writing at any time and all full disclosures will then cease. </li>

<li> The Clinic may condition receipt of treatment upon execution of this consent. </li>


<li> The Clinic may phone, email, or send a text to you to discuss the findings of your health questionnaire.	</li>
</ul>

</span>













<h4>Notice of Privacy</h4>


<p>How we use your information at Medfit Proactive Healthcare.
In this Notice, we explain how we collect information about you, how we use it and how you can interact with us about it. Who We Are When we talk about “MPH”, or “us” or “we” in this Notice, we are referring to Medfit Proactive Healthcare. We will share information about you within Medfit Proactive Healthcare or other referring sources such as Clinic’s, GP’s or Consultants.
How We Collect Information about You and Your Consent
We collect personal information, for example when you attend our practice. We will also collect information from others, such as GP’s or other Referring Physicians, Clinicians or other referral sources. We also use cloud technology to store data including your information, to support our infrastructure and to deliver you a professional service. By becoming a patient of the MPH you are consenting to us collecting and using your information in the ways we explain in this notice and any future versions of it.</p>

<h4>How We Keep Your Information Safe We know that you care about how information about you is used, stored and shared.
We appreciate your trust in us to do that. To protect your information we use security measures that comply with Irish law and meet international standards. This includes computer safeguards and secure files and buildings.</h4>

<p>What We Use Your Information For Providing you Professional Medical Services We gather information about you and we use this information to:
<ul>
<li>administer the necessary healthcare for you,</li>
<li>make recommendations about the appropriate care required for you,</li>
<li> decide how we can plan for your future healthcare</li>
</ul>
</p>

<h4>Developing Our Relationship – Patient Experience</h4>
<p>We may make you aware of treatments which may be of interest to you. We may do this by phone, mail, email, text or through other digital media. You can decide to change your contact preferences at any stage by simply contacting reception Tel: 01 2784423 E-mail: info@medfit.ie
</p>
<h4>Data Analytics – Using Information in Our Business</h4>

<p>We analyse the information that we collect and hold on you. This helps us understand our relationship with you. Our analysis helps us to offer you healthcare we believe will be of interest to you.
We may provide others within MPH, or other referral sources, with reports that contain information on your medical condition. We compile these reports only to provide the best care possible. MPH will never share data with third parties without your permission given in our Patient Consent Form.
</p>

<h4>Our Legal Obligations</h4>

<p>We have legal obligations to prevent fraud. These obligations mean we have to continually update our customer information. Where false or misleading information is given to us, or we have suspect criminal activity we will record this and tell the appropriate law enforcement agencies, which may be within and outside Ireland.
</p>
<h4>Your Information and Third Parties</h4>

<p>We use other companies and individuals to run our practice management software. Our practice management software is TM2 and is hosted in ISO27001.
We contract with all third parties to whom we give your information for these purposes to keep your information confidential and to respect the law on data protection.
Your credit/debit card details are not stored.</p>

<h4>Accessing and Managing Your Information</h4>

<p>We try to make sure that the information we have about you is accurate and up-to-date. Sometimes we may ask you to verify that the information we have remains accurate. If your information changes or you believe we have information which is inaccurate or not up-to-date please let us know and we will change it. You have the right to request your data at any time.</p>

<p>If you think your data rights have been breached, you are able to raise a complaint with the Office of the Data Protection Commissioner. You can contact the ODPC at Data Protection Commissioner, Canal House, Station Road, Portarlington, R32 AP23, Co. Laois or by telephone on +353 57 8684800 or +353 (0)761 104 800 or Lo Call 1890 252 231 or email info@dataprotection.ie.
</p>
<p>Data Protection Compliance
Our Data Protection Manager is:</p>

Claire Mulligan<br/>
IACT GDPR Certification<br/>
May 2018


  </>
)
};

export default Terms;
