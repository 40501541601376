import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoadingDots } from "@workday/canvas-kit-react";
import { URL, setTokenAndUsername } from "./../services/authProvider";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "./logo.png";
import Copyright from "../Copyright";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      backgroundColor: "rgb(0, 92, 185)",
    },
    "&:active": {
      backgroundColor: "rgb(0, 92, 185)",
    },
    "&:visited": {
      backgroundColor: "rgb(0, 92, 185)",
    },
    "&:focus": {
      backgroundColor: "rgb(0, 92, 185)",
    },
    "&:focus-within": {
      backgroundColor: "rgb(0, 92, 185)",
    },
  },
  header: {
    textAlign: "center",
    margin: theme.spacing(3, 0, 2),
  },
  dots: {
    alignItems: "center",
    paddingTop: "10px",
    width: "100%",
    textAlign: "center",
    margin: "auto",
  },
  error: {
    color: "rgb(255, 83, 71)",
  },
}));

const createOnChange = (ref) => (event) => {
  ref.current = event.target.value;
};

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const userNameRef = useRef();
  const passwordRef = useRef("");
  const history = useHistory();
  const onSubmit = async (event) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);
    let payload = {
      username: userNameRef.current,
      password: passwordRef.current,
    };

    const x = await fetch(`${URL}token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const json = await x.json();

    if ("detail" in json) {
      if (
        json.detail === "No active account found with the given credentials"
      ) {
        setError("Please check the username and password is correct");
        setLoading(false);
        console.log("no account");
      }
      setLoading(true);
    }

    if ("access" in json && "refresh" in json) {
      setTokenAndUsername(json, userNameRef.current);
      history.push("/main");
    } else {
      setLoading(false);
    }
  };
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="Logo" width="130px" />
        <Typography component="h1" variant="h5" className={classes.header}>
          Welcome to Medfit’s Occupational Injury/Illness Registration Portal
        </Typography>
        {error && (
          <p className={classes.error} color="secondary">
            {error}
          </p>
        )}
        <form className={classes.form} Validate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            type="email"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            maxLength="70"
            onChange={createOnChange(userNameRef)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            maxLength="15"
            onChange={createOnChange(passwordRef)}
            autoComplete="current-password"
          />

          {loading && <LoadingDots className={classes.dots} />}
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
      <Copyright />
    </Container>
  );
};

export default LoginPage;
