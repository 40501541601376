import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import logo from './logo.png';
import Typography from '@material-ui/core/Typography';
import { USERNAME,resetTokenAndUsername} from './../services/authProvider';
import Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme) => ({
  header: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
  flexGrow: 1,
  },
}));

const Header = () => {
  const history = useHistory();
  const onClick = () => {
window.location.reload();
    resetTokenAndUsername();
  };

const classes = useStyles();
  return (

    <div className={classes.header} >
      <AppBar position="sticky"  >
        <Container maxWidth="lg">
          <Toolbar >
            <Typography  variant="h6" className={classes.title}>
              <img src={logo} alt="Logo" width="100px"  />
            </Typography>
            <Button onClick={onClick} edge="end" color="inherit">Log Out</Button>
          </Toolbar>
        </Container >
      </AppBar>
    </div>
  );
};

export default Header;
