export let TOKEN = '';
export let ACCESS = '';
export let REFRESH = '';
export let USERNAME = '';
export let BEARER = 'Bearer ' + ACCESS;
export let URL = 'https://mpatinternal.ie/patientapi/';
export const setTokenAndUsername = (token, username) => {
  TOKEN = 'set';
  ACCESS = token.access;
  REFRESH = token.refresh;
  USERNAME = username;
  BEARER =' Bearer ' + ACCESS;
}
export const resetTokenAndUsername = () => {
  TOKEN = '';
  ACCESS = '';
  REFRESH = '';
  USERNAME = '';
  BEARER =' Bearer ' + ACCESS;

}

export const isExperied = () => {
  if (ACCESS !== ''){
    const jwt = JSON.parse(atob(ACCESS.split('.')[1]));

    if (Date.now() >= jwt.exp * 1000) {
      return true;
    }
  }

}

export const resetToken = (access) =>{
  ACCESS = access;
}
