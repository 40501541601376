import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ACCESS,
  URL,
  REFRESH,
  BEARER,
  setTokenAndUsername,
} from "./../services/authProvider";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  heading: {
    textAlign: "center",
    paddingBottom: "20px",
  },
}));

const rtws = [
  {
    value: 5,
    label: "Definitely NO",
  },
  {
    value: 4,
    label: "Not likely",
  },
  {
    value: 3,
    label: "NOT Sure",
  },
  {
    value: 2,
    label: "Likely",
  },
  {
    value: 1,
    label: "Definitely Yes",
  },
];

const healths = [
  {
    value: 5,
    label: "Very Poor",
  },
  {
    value: 4,
    label: "Poor",
  },
  {
    value: 3,
    label: "Average",
  },
  {
    value: 2,
    label: "Good",
  },
  {
    value: 1,
    label: "Excellent",
  },
];

const physicals = [
  {
    value: 1,
    label: "LOW physical demand",
  },
  {
    value: 2,
    label: "AVERAGE physical demand",
  },
  {
    value: 3,
    label: "HIGH physical demand",
  },
];
const severitys = [
  {
    value: 5,
    label: "Very Severe",
  },
  {
    value: 4,
    label: "Severe",
  },
  {
    value: 3,
    label: "Average",
  },
  {
    value: 2,
    label: "Slight",
  },
  {
    value: 1,
    label: "Very Slight",
  },
];
const Score = () => {
  const history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();

    //setLoading(true);
    let payload = {
      ans1: health,
      ans2: physical,
      ans3: rtw,
      ans4: severity,
    };
    fetch(`${URL}answers/`, {
      method: "POST",
      headers: {
        Authorization: BEARER,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Request failed.");
      })
      .then((data) => {
        console.log(data);
        history.push("/main");
      });
  };
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = React.useState("Average");

  const handleChange4 = (event) => {
    setSeverity(event.target.value);
  };
  const [rtw, setRtw] = React.useState("Average");

  const handleChange3 = (event) => {
    setRtw(event.target.value);
  };

  const [health, setHealth] = React.useState("Average");

  const handleChange2 = (event) => {
    setHealth(event.target.value);
  };
  const [physical, setPhysical] = React.useState("AVERAGE physical demand");

  const handleChange = (event) => {
    setPhysical(event.target.value);
  };
  const classes = useStyles();
  return (
    <>
      <Typography component="h1" variant="h5" className={classes.heading}>
        Medfit Occupational Injury & Illness Registration Form
      </Typography>

      <form className={classes.form} Validate onSubmit={onSubmit}>
        <TextField
          id="PHYSICAL"
          select
          required
          fullWidth
          variant="outlined"
          margin="normal"
          label="In general, would you say your health is:"
          value={health}
          onChange={handleChange2}
        >
          {healths.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="PHYSICAL"
          select
          required
          fullWidth
          variant="outlined"
          margin="normal"
          label="How would you rate the PHYSICAL demands of your work?"
          value={physical}
          onChange={handleChange}
        >
          {physicals.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="rtw"
          select
          label="Do you think you will be able to work without restrictions within the next 4 weeks?"
          required
          fullWidth
          variant="outlined"
          margin="normal"
          value={rtw}
          onChange={handleChange3}
        >
          {rtws.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="severity"
          select
          label="How would you rate the severity of your injury?"
          required
          fullWidth
          variant="outlined"
          margin="normal"
          value={severity}
          onChange={handleChange4}
        >
          {severitys.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <div>
          <Button disabled={loading} color="primary" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default Score;
