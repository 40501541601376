import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import LoginPage from './components/login/LoginPage';
import MainPage from './components/main/MainPage';
import PublicRoute from './components/router/PublicRoute';
import PrivateRoute from './components/router/PrivateRoute';
import { TableContext } from './components/TableContext';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0875e1'
    }
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
          <TableContext.Provider
            // @ts-ignore
            value={""}
          >
            <Switch>
              <PublicRoute  component={LoginPage} exact path="/"/>
              <PrivateRoute component={MainPage} exact path="/main" />

            </Switch>
          </TableContext.Provider>

      </Router>
    </MuiThemeProvider>
  );
}

export default App;
